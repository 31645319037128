import React, { useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import moment from 'moment-timezone'
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import * as srv from 'admin/services'
import { useStores, useRequest } from 'admin/hooks'
import { formatDayName, slotDisplay } from 'admin/utils/helper'
import { TAG_CODE } from 'admin/config'

import InvoiceAndIcon from 'admin/components/invoice-icon'

import CheckboxGroup from './checkbox-group'
import styles from './styles'

const getTags = (bookingTags) => {
  const pickup = []
  const delivery = []
  if (bookingTags) {
    bookingTags.map((bookingTag) => {
      if (bookingTag.type === 'pickup') {
        pickup.push(bookingTag)
      } else {
        delivery.push(bookingTag)
      }
      return true
    })
  }
  return {
    pickup,
    delivery,
  }
}

const useStyles = makeStyles(styles)

const InvoiceCheckboxGroup = ({ loading, list, setList }) => {
  const handleChangeCheckbox = useCallback((event) => {
    setList((prev) => {
      return prev.map((object) => {
        if (object.label === event.target.name) {
          return {
            ...object,
            isChecked: event.target.checked,
          }
        }
        return object
      })
    })
  }, [])

  return (
    <FormControl
      component="fieldset"
      margin="normal"
    >
      <FormLabel component="legend">
        <Typography variant="h4">Invoices</Typography>
      </FormLabel>
      {loading ? (
        <CircularProgress
          color="inherit"
          size={20}
        />
      ) : (
        <FormGroup>
          {list.map((option) => {
            return (
              <FormControlLabel
                key={option.key}
                control={
                  <Checkbox
                    checked={option.isChecked}
                    onChange={handleChangeCheckbox}
                    name={option.label}
                  />
                }
                label={
                  <InvoiceAndIcon
                    key={option.prestoId}
                    invoice={option}
                  />
                }
              />
            )
          })}
        </FormGroup>
      )}
    </FormControl>
  )
}

const Services = () => {
  const classes = useStyles()
  const { bookingTagStore, bookingStore, adminStore } = useStores()
  const [pickupTags, setPickupTags] = useState([])
  const [deliveryTags, setDeliveryTags] = useState([])

  const { isAdmin, isOfficeUser, isCounterStaff } = adminStore

  const [invoiceCheckboxList, setInvoiceCheckboxList] = useState([])
  const [notCompletedNotCancelledInvoices, setNotCompletedNotCancelledInvoices] = useState([])

  const getCheckboxes = (tag, selectedTags, type) => {
    const arrayCheck = Array.isArray(selectedTags)
    const tagIds = arrayCheck ? selectedTags.map((st) => st.tagId) : []
    const curtain =
      arrayCheck && selectedTags.find((st) => st.tag?.posCode === TAG_CODE.CURTAIN_WITH_DISMANTLING)
    const rooms =
      tag.posCode === TAG_CODE.CURTAIN_WITH_DISMANTLING && !!curtain
        ? curtain.additionalInfo.numberOfRoom || 0
        : 0
    return {
      tagId: tag.id,
      additionalInfo: {
        numberOfRoom: rooms,
      },
      isChecked:
        isCounterStaff && tag.name === 'Laundry' && type === 'delivery'
          ? true
          : tagIds.includes(tag.id),
      type,
      tag,
    }
  }

  const { request: searchInvoice, loading: searchInvoiceLoading } = useRequest(srv.searchInvoice, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && Array.isArray(data.list) && data.list?.length) {
        setNotCompletedNotCancelledInvoices(data.list)
      }
    },
  })

  useEffect(() => {
    if (bookingStore.selected?.customerId) {
      searchInvoice({
        customerId: bookingStore.selected.customerId,
        orderStatusNotIn: ['Completed', 'Cancelled'],
        fromDate: moment().subtract(1, 'years').format('DD/MM/YYYY'),
      })
    }
  }, [bookingStore.selected?.customerId])

  useEffect(() => {
    bookingTagStore.fetch() // All tags from setting page
  }, [])

  useEffect(() => {
    const prevBookingInvoices =
      bookingStore.selected && bookingStore.selected.invoices ? bookingStore.selected.invoices : []
    const otherInvoicesLength = notCompletedNotCancelledInvoices?.length || 0

    if (otherInvoicesLength || prevBookingInvoices?.length) {
      const invoicePrestoIds = notCompletedNotCancelledInvoices.map((inv) => inv.prestoId)
      const valuePrestoId = prevBookingInvoices.map((inv) => inv.prestoId)

      const valueTicked = prevBookingInvoices.reduce((previousValue, currentValue) => {
        if (!invoicePrestoIds.includes(currentValue.prestoId)) {
          previousValue.push({
            key: currentValue.id,
            label: currentValue.prestoId,
            isChecked: true,
            ...currentValue,
          })
        }
        return previousValue
      }, [])

      const otherInvoices = notCompletedNotCancelledInvoices.reduce(
        (previousValue, currentValue) => {
          if (valuePrestoId.includes(currentValue.prestoId)) {
            previousValue.push({
              key: currentValue.id,
              label: currentValue.prestoId,
              isChecked: true,
              ...currentValue,
            })
          } else {
            previousValue.push({
              key: currentValue.id,
              label: currentValue.prestoId,
              isChecked: false,
              ...currentValue,
            })
          }
          return previousValue
        },
        [],
      )

      setInvoiceCheckboxList([...valueTicked, ...otherInvoices])
    }
  }, [notCompletedNotCancelledInvoices])

  useEffect(() => {
    if (invoiceCheckboxList?.length) {
      const ticked = invoiceCheckboxList.reduce((previousValue, currentValue) => {
        if (currentValue.isChecked) {
          previousValue.push(currentValue)
        }
        return previousValue
      }, [])
      bookingStore.updateSelected('invoices', ticked)
    } else {
      // prevent crushing other component, set invoices to []
      bookingStore.updateSelected('invoices', [])
    }
  }, [invoiceCheckboxList])

  const scheduleInfo = computed(() => {
    if (!bookingStore.newBooking && bookingStore.selected) {
      const slot = slotDisplay(bookingStore.selected.slot?.start, bookingStore.selected.slot?.end)
      return [
        {
          title: 'Driver',
          value: bookingStore.selected.driver
            ? `${bookingStore.selected.driver.name} (${bookingStore.selected.driver.code})`
            : '-',
        },
        { title: 'Date', value: formatDayName(bookingStore.selected.date) },
        { title: 'Timeslot', value: slot },
      ]
    }
    return null
  }).get()

  useEffect(() => {
    if (bookingTagStore.list && bookingTagStore.list.length) {
      const allPickupTags = bookingTagStore.list.filter(
        (tagStoreList) => !!tagStoreList.isPickupTag,
      )
      const allDeliveryTags = bookingTagStore.list.filter(
        (tagStoreList) => !!tagStoreList.isDeliveryTag,
      )

      const { pickup, delivery } = getTags(bookingStore.selected?.bookingTags)

      const pickupCheckboxes = allPickupTags.map((tag) => getCheckboxes(tag, pickup, 'pickup'))
      setPickupTags(pickupCheckboxes)
      const deliveryCheckboxes = allDeliveryTags.map((tag) =>
        getCheckboxes(tag, delivery, 'delivery'),
      )
      setDeliveryTags(deliveryCheckboxes)
    }
  }, [bookingTagStore.list, bookingStore.selected?.bookingTags])

  useEffect(() => {
    if (pickupTags.length > 0 || deliveryTags.length > 0) {
      const selectedPickupTags = pickupTags.filter((tag) => tag.isChecked === true)
      const selectedDeliveryTags = deliveryTags.filter((tag) => tag.isChecked === true)

      const allSelectedTags = [selectedPickupTags, selectedDeliveryTags].flat(1)

      bookingStore.setCheckedTags(allSelectedTags)
    }
  }, [deliveryTags, pickupTags])

  return (
    <div className={classes.serviceWrapper}>
      {!bookingStore.newBooking && (
        <>
          <Typography>Schedule</Typography>
          <div className={classes.schedule}>
            {Array.isArray(scheduleInfo) &&
              scheduleInfo.map((info) => (
                <div
                  key={info.title}
                  className={classes.margin}
                >
                  <Typography variant="h4">{info.title}</Typography>
                  <Typography variant="h4">{info.value}</Typography>
                </div>
              ))}
          </div>
        </>
      )}
      <Typography variant="h3">Services</Typography>
      {isAdmin || isOfficeUser ? (
        <div className={classes.serviceOptions}>
          <CheckboxGroup
            icon="pickup"
            title="Pick-up Service"
            data={pickupTags}
            setTags={setPickupTags}
          />
          <div className={classes.serviceWrapper}>
            <CheckboxGroup
              icon="delivery"
              title="Delivery Service"
              data={deliveryTags}
              setTags={setDeliveryTags}
            />
            <InvoiceCheckboxGroup
              loading={searchInvoiceLoading}
              list={invoiceCheckboxList}
              setList={setInvoiceCheckboxList}
            />
          </div>
        </div>
      ) : (
        <InvoiceCheckboxGroup
          loading={searchInvoiceLoading}
          list={invoiceCheckboxList}
          setList={setInvoiceCheckboxList}
        />
      )}
    </div>
  )
}

export default observer(Services)
